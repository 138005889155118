const dashboardType = {
  // for pass percentage
  PASS_PERCENTAGE_CALL: "PASS_PERCENTAGE_CALL",
  PASS_PERCENTAGE_CALL_COMMIT: "PASS_PERCENTAGE_CALL_COMMIT",
  PASS_PERCENTAGE_CALL_ROLLBACK: "PASS_PERCENTAGE_CALL_ROLLBACK",

  // for single case
  SINGLE_CASE_CALL: "SINGLE_CASE_CALL",
  SINGLE_CASE_CALL_COMMIT: "SINGLE_CASE_CALL_COMMIT",
  SINGLE_CASE_CALL_ROLLBACK: "SINGLE_CASE_CALL_ROLLBACK",
  SINGLE_CASE_CLEAR_DATA: "SINGLE_CASE_CLEAR_DATA",
};

export default dashboardType;

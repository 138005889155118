import React from "react";
import Plot from "react-plotly.js";
import { Container } from "@amzn/awsui-components-react-v3";

const Bar = (props) => {
  return (
    <Container>
      <Plot
        data={[
          {
            x: props.x,
            y: props.y,
            type: "bar",
            marker: { color: props.color },
          },
        ]}
        layout={{ title: props.title, autosize: true }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
      />
    </Container>
  );
};

export default Bar;

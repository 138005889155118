export const menuItems = [
  {
    text: "Home",
    href: "/",
    type: "dropdown",
    options: [
      {
        text: "Jasmine - RPM & NRMSE",
        href: "/jasmine/rpm-nrmse",
      },
      {
        text: "Brahms - RPM & NRMSE",
        href: "/brahms/rpm-nrmse",
      },
      {
        text: "Brahms - PUT Algorithm",
        href: "/brahms/put",
      },
      {
        text: "Brahms - Sagemaker Cluster",
        href: "/brahms/cluster",
      },
      {
        text: "Vienna - RPM & NRMSE",
        href: "/vienna/rpm-nrmse",
      },
      {
        text: "Vienna - PUT Algorithm",
        href: "/vienna/put",
      },
    ],
  },
  {
    text: "Upload",
    type: "dropdown",
    options: [
      {
        text: "Manual Upload",
        href: "/manual-upload",
      },
    ],
  },
];

export const helpItem = {
  text: "Help & Support",
  type: "dropdown",
  options: [
    {
      text: "JIRA Dashboard",
      href: "https://issues.labcollab.net/secure/Dashboard.jspa?selectPageId=53025",
      useATag: true,
      newTab: true,
    },
    {
      text: "How to Create JIRA",
      href: "https://wiki.labcollab.net/confluence/display/WDI/How+to+Create+Jira+Reporting+to+WISE+Team#HowtoCreateJiraReportingtoWISETeam-SteptoCreateaJira",
      useATag: true,
      newTab: true,
    },
  ],
};

export const analyticsItem = {
  text: "Analytics",
  href: "/analytics",
};

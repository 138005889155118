import dashboardType from "../types/dashboard-type";
import constants from "../../config/constants";
import { TableDataResponse } from "../../model/http-json";

const initialState = {
  tableData: [] as TableDataResponse,
  tableDataLoadingStatus: constants.LOADING_BEFORE_START,
  errorMsg: "",
  dashboardType: constants.PASS_PERSENTAGE,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardType.SINGLE_CASE_CLEAR_DATA:
      return {
        ...state,
        tableData: [] as TableDataResponse, //clear data
        tableDataLoadingStatus: constants.LOADING_BEFORE_START, //reset status
      };
    case dashboardType.PASS_PERCENTAGE_CALL:
      return {
        ...state,
        tableData: [] as TableDataResponse, //re-init tableData
        tableDataLoadingStatus: constants.LOADING_LOAD,
        dashboardType: constants.PASS_PERSENTAGE,
      };
    case dashboardType.SINGLE_CASE_CALL:
      return {
        ...state,
        tableData: [] as TableDataResponse, //re-init tableData
        tableDataLoadingStatus: constants.LOADING_LOAD,
        dashboardType: constants.SINGLE_CASE,
      };
    case dashboardType.PASS_PERCENTAGE_CALL_COMMIT:
    case dashboardType.SINGLE_CASE_CALL_COMMIT:
      return {
        ...state,
        tableDataLoadingStatus: constants.LOADING_SUCCESS,
        tableData: action.payload,
      };
    case dashboardType.PASS_PERCENTAGE_CALL_ROLLBACK:
    case dashboardType.SINGLE_CASE_CALL_ROLLBACK:
      return {
        ...state,
        tableDataLoadingStatus: constants.LOADING_FAIL,
        errorMsg: action.payload
          ? action.payload.message
          : "Requst failed! Contact WISE team to debug...",
      };
    default:
      return state;
  }
};

export default dashboardReducer;

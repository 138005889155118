export const sidebarData = [
  {
    text: "Jasmine",
    children: [
      {
        text: "RPM & NRMSE",
        href: "/jasmine/rpm-nrmse",
      },
    ],
  },
  {
    text: "Brahms",
    children: [
      {
        text: "RPM & NRMSE",
        href: "/brahms/rpm-nrmse",
      },
      {
        text: "PUT Algorithm",
        href: "/brahms/put",
      },
      {
        text: "Sagemaker Cluster",
        href: "/brahms/cluster",
      },
    ],
  },
  {
    text: "Vienna",
    children: [
      {
        text: "RPM & NRMSE",
        href: "/vienna/rpm-nrmse",
      },
      {
        text: "PUT Algorithm",
        href: "/vienna/put",
      },
    ],
  },
];

// general constants
const constants = {
  INITIATIVE: "dataannotation",

  NA: "N/A",
  NULL: "Null",

  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",

  // These are used for dispatching/listening to custom Hub events.
  SESSION_EXPIRED: "session_expired",
  SESSION_WILL_EXPIRE: "session_will_expire",
  SESSION_TIMER_INTERVAL: 30000,

  // loading status
  LOADING_LOAD: 0,
  LOADING_SUCCESS: 1,
  LOADING_FAIL: -1,
  LOADING_BEFORE_START: 10,

  // general status(we can keep all our status values uniformed)
  // file parse status is using this
  STATUS_SUCCESS: "success",
  STATUS_FAIL: "fail",
  STATUS_ERROR: "error", // there are some legacy status using error
  STATUS_PROCESS: "in_process",
  STATUS_NOT_START: "not_start",
  STATUS_DONE: "done",

  UPLOAD_CHECK_FILE_SUCCESS: "succeed",
  UPLOAD_CHECK_FILE_FAIL: "failed",

  TABLE_DEFAULT_MIN_WIDTH: "100px",

  LANDING_STATUS_PROCESS: "in_process",
  LANDING_STATUS_ARCHIVED: "archived",
  LANDING_STATUS_NOT_START: "not_start",

  //dashboard combinations limit
  COMBINATIONS_LIMIT: 1000,

  //dashboard real-time type
  PASS_PERSENTAGE: 0,
  SINGLE_CASE: 1,

  //KPI parameters(TODO: add paras for PUT Algorithm)
  RPM_NRMSE_PARAMETER: ["RPM", "NRMSE"],
  PUT_PARAMETER: [
    "IU_TP",
    "IU_FP",
    "IU_TN",
    "IU_FN",
    "UU_TP",
    "UU_FP",
    "UU_TN",
    "UU_FN",
  ],
  CLUSTER_PARAMETER: ["cluster 0", "cluster 1", "cluster 2"],
  RPM_NRMSE_COLOR: ["green", "green"],
  PUT_COLOR: [
    "blue",
    "green",
    "pink",
    "purple",
    "orange",
    "brown",
    "gray",
    "turquoise",
  ],
  RPM_NRMSE_LIMIT: [90, 1],

  //dashboard categories
  RPM_NRMSE_CATEGORY: "rpm-nrmse",
  PUT_CATEGORY: "put",
  CLUSTER_CATEGORY: "cluster",
};
export default constants;

// UI text for status
export const STATUS_TEXT = {
  [constants.STATUS_SUCCESS]: "Success",
  [constants.STATUS_FAIL]: "Fail",
  [constants.STATUS_ERROR]: "Fail",
  [constants.STATUS_PROCESS]: "In Progress",
  [constants.STATUS_NOT_START]: "Not Started",
  [constants.STATUS_DONE]: "Done",
};

// UI text for Landing status
export const LANDING_STATUS_TEXT = {
  [constants.LANDING_STATUS_NOT_START]: "Not Started",
  [constants.LANDING_STATUS_PROCESS]: "In Progress",
  [constants.LANDING_STATUS_ARCHIVED]: "Archived",
};

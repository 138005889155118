import React, { Component } from "react";
import {
  ExpandableSection,
  Alert,
  ColumnLayout,
} from "@amzn/awsui-components-react/polaris";
// constants
import { FILE_TYPE, FILE_NAME_FORMAT } from "../../../config/upload-config";

// file types
const SHOW_TYPES = [FILE_TYPE.RPM_NRMSE, FILE_TYPE.PUT];

class UploadFileSupport extends Component {
  render() {
    return (
      <ExpandableSection header="Which files are supported">
        <Alert>
          The files you are going to upload should follow below file name
          formats.
        </Alert>
        <br />
        <ColumnLayout columns={2} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            {/* Col_1: Files */}
            <div className="awsui-util-spacing-v-s">
              {/* column1 items */}
              {SHOW_TYPES.map((type: string, index: number) => (
                <div key={index}>
                  <div className="awsui-util-label">
                    {FILE_NAME_FORMAT[type].text}
                  </div>
                  <div>
                    <b>{"Name format: "}</b>
                    {FILE_NAME_FORMAT[type].format}
                  </div>
                  <div>
                    <b>{"Example: "}</b>
                    {FILE_NAME_FORMAT[type].example}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ColumnLayout>
      </ExpandableSection>
    );
  }
}

export default UploadFileSupport;

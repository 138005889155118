import React, { Component } from "react";
import {
  Alert,
  Table,
  Button,
  TablePropertyFiltering,
  TablePagination,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from "@amzn/awsui-components-react/polaris";
import {
  defaultPageSize,
  pageSizeOptions,
  columnDefinitions,
  defaultSortingColumn,
  sortableColumns,
  visibleColumns,
  filteringOptions,
} from "./table-config";
import { exportCSVFunc } from "@amzn/lens-react-ui";
import constants from "../../../config/constants";
import { UploadTableRow } from "../../../model/upload";

// declare prop check
type Props = {
  data: Array<UploadTableRow>;
  getData: (syncMode: boolean) => void; // func to get table data
  loadingStatus: number;
} & typeof defaultProps;

type State = typeof initialStatue;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialStatue = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
});

class UploadHistoryTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialStatue;

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  render() {
    const { data, loadingStatus, getData } = this.props;

    return (
      <React.Fragment>
        {loadingStatus === constants.LOADING_FAIL && (
          <Alert type="error" className="mb-2">
            Unexpected errors. Please try refreshing the table.
          </Alert>
        )}
        <Table
          loadingText="Loading resources"
          columnDefinitions={columnDefinitions}
          items={data}
          loading={loadingStatus === constants.LOADING_LOAD}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>
                  Upload History &nbsp;
                  <span className="awsui-util-header-counter">{`(${data.length})`}</span>
                </h2>
              </div>
              <div className="awsui-util-action-stripe-group">
                <Button icon="refresh" onClick={() => getData(false)}></Button>
                <Button
                  icon="download"
                  variant="primary"
                  onClick={() => exportCSVFunc(data, "upload_history")}
                >
                  Export
                </Button>
              </div>
            </div>
          }
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No resources</b>
              </div>
              <p className="awsui-util-mb-s">No resources to display.</p>
            </div>
          }
          noMatch={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No matches</b>
              </div>
              <p className="awsui-util-mb-s">We can’t find a match.</p>
              <div className="awsui-util-mb-m">
                <Button>Clear filter</Button>
              </div>
            </div>
          }
        >
          <TablePropertyFiltering
            filteringOptions={filteringOptions}
            groupValuesText="Values"
            groupPropertiesText="Properties"
            operationAndText="and"
            operationNotAndText="and not"
            operationOrText="or"
            operationNotOrText="or not"
            clearFiltersText="Clear filter"
            placeholder="Filter resources by property or value"
            allowFreeTextFiltering={true}
            filteringCountTextFunction={(count) =>
              `${count} ${count === 1 ? "match" : "matches"}`
            }
          ></TablePropertyFiltering>
          <TablePagination
            pageSize={defaultPageSize}
            onPaginationChange={this._onPaginationChange}
          ></TablePagination>
          <TableSorting
            sortingColumn={defaultSortingColumn}
            sortingDescending={true}
            sortableColumns={sortableColumns}
          ></TableSorting>
          <TablePreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
          >
            <TablePageSizeSelector
              title="Page size"
              options={pageSizeOptions}
            />
            <TableWrapLines
              label="Wrap lines"
              description="Enable to wrap table cell content, disable to truncate text."
            />
            <TableContentSelector
              title="Select visible columns"
              options={visibleColumns}
            />
          </TablePreferences>
        </Table>
      </React.Fragment>
    );
  }
}

export default UploadHistoryTable;

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = (tableHeaders: Array<string>) => {
  let dynamicCols = [] as Array<any>;
  tableHeaders.forEach((element) => {
    const curCol = {
      id: element,
      header: element,
      cell: (item) => item[element],
      minWidth: "100px",
    };
    dynamicCols.push(curCol);
  });

  return dynamicCols;
};

// define default sorting column
export const defaultSortingColumn = "Pass Percentage";

// define sortable columns
export const sortableColumns = (tableHeaders: Array<string>) => {
  let dynamicCols = [] as Array<any>;
  tableHeaders.forEach((element) => {
    const curCol = {
      id: element,
      field: element,
    };
    dynamicCols.push(curCol);
  });

  return dynamicCols;
};

// define visiable columns
export const visibleColumns = (tableHeaders: Array<string>) => {
  let dynamicOptions = [] as Array<any>;
  tableHeaders.forEach((element) => {
    const curOption = {
      id: element,
      label: element,
      visible: true,
    };
    dynamicOptions.push(curOption);
  });

  return [
    {
      label: "Properties",
      options: dynamicOptions,
    },
  ];
};

// define filtering options
export const filteringOptions = (tableHeaders: Array<string>) => {
  let dynamicOptions = [] as Array<any>;
  tableHeaders.forEach((element) => {
    const curOption = {
      groupValuesLabel: element,
      propertyKey: element,
      propertyLabel: element,
      values: [],
    };
    dynamicOptions.push(curOption);
  });

  return dynamicOptions;
};

export const features = [
  "propertyFiltering",
  "pagination",
  "selection",
  "sorting",
];

import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Navbar from "../navbar/navbar";
import MainRouter from "./main-router";
import Sidebar from "../sidebar/sidebar"


interface StateProps {}
// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  collapse: false, // sidebar collpase flag
});

class MainContent extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidMount() {
    // get metadata
    // this.props.dispatch(getMetadata());
  }

  // sidebar toggle button onClick func
  _onClickToggle = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  render() {
    const { collapse } = this.state;
    return (
      <div>
        <Navbar />
        <Sidebar collapse={collapse} onClick={this._onClickToggle} />
        <MainRouter collapse={collapse} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default connect<StateProps>(mapStateToProps)(MainContent);

import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import NotFound from "../not-found";
import ManualUpload from "../upload/manual-upload";
import Dashboard from "../landing/dashboard";

// declare prop check
type Props = { collapse: boolean } & typeof defaultProps;

// declare init state & default props
const defaultProps = Object.freeze({});

// styled component
const StyledDiv = styled.div<{ collapse: boolean }>`
  margin-left: ${(props) => (props.collapse ? "50px" : "250px")};
  margin-top: 50px;
  padding: 30px;
`;

// dashboard paths
const Paths = [
  "/jasmine/rpm-nrmse",
  "/brahms/rpm-nrmse",
  "/brahms/put",
  "/brahms/cluster",
  "/vienna/rpm-nrmse",
  "/vienna/put",
];
class MainRouter extends Component<Props> {
  render() {
    const { collapse } = this.props;
    return (
      <StyledDiv collapse={collapse}>
        <Switch>
          <Route path={Paths} exact component={Dashboard} />
          <Route path="/manual-upload" exact component={ManualUpload} />
          <Route path="/not-found" exact component={NotFound} />
          <Redirect from="/" to={Paths[0]} />
          <Redirect to="/not-found" />
        </Switch>
      </StyledDiv>
    );
  }
}

export default MainRouter;

import { config } from './../../config/config';
import endpoints from "../../config/endpoints";
import emailVerificationType from "../types/email-verification-type"
import { username } from "../../cognito-auth/session";


export const verifyEmail = () => ({
  type: emailVerificationType.CHECK_EMAIL_VERIFIED,
  http: {
    cognito: {
      effect: {
        url: config.GENIE_URL + endpoints.verifyEmailEndpoint(),
        method: "POST",
        json: { email: `${username}@amazon.com` },
      },
      commit: {
        type: emailVerificationType.CHECK_EMAIL_VERIFIED_COMMIT,
      },
      rollback: {
        type: emailVerificationType.CHECK_EMAIL_VERIFIED_ROLLBACK,
      },
    },
  },
});
  
export const sendVerificationEmail = () => ({
  type: emailVerificationType.SEND_VERIFICATION_EMAIL,
  http: {
    cognito: {
      effect: {
        url: config.GENIE_URL + endpoints.sendverificationEmailEndpoint(),
        method: "POST",
        json: { email: `${username}@amazon.com` },
      },
      commit: {
        type: emailVerificationType.SEND_VERIFICATION_EMAIL_COMMIT,
      },
      rollback: {
        type: emailVerificationType.SEND_VERIFICATION_EMAIL_ROLLBACK,
      },
    },
  },
});
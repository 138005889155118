import React, { Component } from "react";
import { connect } from "react-redux";
import Amplify, { Hub } from "@aws-amplify/core";
import { getUserInfo, redirectToLoginPage } from "./cognito-auth/session";
import { config } from "./config/config";
import MainContent from "./containers/main/main";
import constants from "./config/constants";

class App extends Component {
  constructor(props) {
    super(props);
    Amplify.configure(config.AMPLIFY_CONFIG);
    Hub.listen("auth", ({ payload: { event } }) => {
      if (event === constants.SIGNIN) {
        getUserInfo().then((userInfo) => {
          if (userInfo) {
            this.setState({
              authState: constants.AUTHENTICATED,
              userInfo: userInfo,
            });
          }
        });
      } else if (event === constants.SIGNIN_FAILURE) {
        this.setState({ authState: constants.SIGNIN_FAILURE, userInfo: null });
      } else if (event === constants.SIGNOUT) {
        redirectToLoginPage();
      }
    });

    this.state = {
      userInfo: null,
      authState: constants.UNAUTHENTICATED,
    };
  }
  componentDidMount() {
    // login cognito
    setTimeout(
      () => {
        getUserInfo().then((userInfo) => {
          if (userInfo) {
            this.setState({
              authState: constants.AUTHENTICATED,
              userInfo: userInfo,
            });
          } else {
            redirectToLoginPage();
          }
        });
      },
      new URLSearchParams(window.location.search).has("code") ? 3000 : 500
    );
  }

  render() {
    const { authz } = this.props.authzReducer;
    return (
      <React.Fragment>
        {this.state.authState === constants.AUTHENTICATED && authz === 0 && (
          <div style={{ color: "black" }}>Verifying authorization...</div>
        )}
        {this.state.authState === constants.AUTHENTICATED && authz === -1 && (
          <div style={{ color: "black" }}>Sorry, you are not authorized.</div>
        )}
        {this.state.authState === constants.AUTHENTICATED && authz === 1 && (
          <MainContent />
        )}
        {this.state.authState === constants.SIGNIN_FAILURE && (
          <div style={{ color: "black" }}>Logging failed</div>
        )}
        {this.state.authState === constants.UNAUTHENTICATED && (
          <div style={{ color: "black" }}>Authenticating...</div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authzReducer: state.authzReducer,
  };
};

export default connect(mapStateToProps)(App);

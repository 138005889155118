import landingType from "../types/landing-type";
import constants from "../../config/constants";
import { LandingResponse } from "../../model/http-json";

const initialState = {
  landingData: {} as LandingResponse,
  loadingStatus: constants.LOADING_LOAD,
};

const landingReducer = (state = initialState, action) => {
  switch (action.type) {
    case landingType.LANDING_SERVICE_CALL:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case landingType.LANDING_SERVICE_CALL_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        landingData: action.payload,
      };
    case landingType.LANDING_SERVICE_CALL_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default landingReducer;

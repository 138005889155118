import uploadType from "../types/upload-type";
import { UploadStatusResponse } from "../../model/http-json";
import { UploadTableRow } from "../../model/upload";
import { changeDateFormat } from "../../utils/date-utils";
import constants, { STATUS_TEXT } from "../../config/constants";

const initialState = {
  manualUploadData: [] as UploadStatusResponse,
  manualUploadLoadingStatus: constants.LOADING_LOAD,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadType.GET_UPLOAD_STATUS:
      if (action.syncMode) {
        return state;
      }
      return {
        ...state,
        manualUploadLoadingStatus: constants.LOADING_LOAD,
      };
    case uploadType.GET_UPLOAD_STATUS_COMMIT:
      return {
        ...state,
        manualUploadLoadingStatus: constants.LOADING_SUCCESS,
        manualUploadData: Array.isArray(action.payload.data)
          ? formatUploadStatusData(action.payload.data as UploadStatusResponse)
          : [],
      };
    case uploadType.GET_UPLOAD_STATUS_ROLLBACK:
      return {
        ...state,
        manualUploadLoadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default uploadReducer;

/* 
  format upload status data:
    1. change the time format -> "YY-MM-DD hh:mm:ss"
*/
const formatUploadStatusData = (
  data: Array<UploadTableRow>
): Array<UploadTableRow> => {
  data.forEach((item) => {
    item.upload_time = changeDateFormat(item.upload_time);
    item.status = STATUS_TEXT[item.status];
  });
  return data;
};

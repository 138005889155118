// AJAX request related
import { config } from "../../../config/config";
import endpoint from "../../../config/endpoints";
import {
  UploadFileCheckRequest,
  UploadFileCheckResponse,
  UploadPreSignedURLRequest,
  UploadPreSignedURLResponse,
} from "../../../model/http-json";
import { postData, Response } from "../../../utils/cognito-fetch";
// constatns
import { FileStatus } from "../../../model/upload";
import { username } from "../../../cognito-auth/session";
import constants from "../../../config/constants";

export async function fileNameCheckCall(file: File): Promise<FileStatus> {
  /*
    Ajax call to check 1 single file name

*/
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
  };

  try {
    const filename = file.name;
    const url: string = config.UPLOAD_BASE_URL + endpoint.uploadFileNameCheck();
    const data: UploadFileCheckRequest = {
      initiative: constants.INITIATIVE,
      file_name: filename,
    };

    const response: Response = await postData(url, data);

    // http reponse ok
    if (response.ok) {
      const fileCheckResponse: UploadFileCheckResponse =
        response.json as UploadFileCheckResponse;
      if (
        fileCheckResponse.status &&
        fileCheckResponse.status === constants.UPLOAD_CHECK_FILE_SUCCESS
      ) {
        rst.status = constants.LOADING_SUCCESS;
      } else {
        rst.status = constants.LOADING_FAIL;
        rst.message = `${
          fileCheckResponse.error_message
            ? fileCheckResponse.error_message
            : "Unknown error."
        } ${
          fileCheckResponse.error_description
            ? fileCheckResponse.error_description
            : ""
        }`;
      }
    } else {
      //http resonse on ok
      rst.status = constants.LOADING_SUCCESS;
      rst.message = response.status_text;
    }
  } catch (error: any) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}

export async function fileUploadS3Call(file: File): Promise<FileStatus> {
  /*
      Ajax call to upload 1 file to s3
      
      Two steps:
       1. send call to upload service to get pre-signed url
       2. put request to s3
  */
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
  };

  try {
    // step 1: get pre-signed url
    const filename = file.name;
    const url: string = config.UPLOAD_BASE_URL + endpoint.uploadPreSignedURL();
    const data: UploadPreSignedURLRequest = {
      initiative: constants.INITIATIVE,
      file_name: filename,
      user_name: username,
    };

    // the response1 is our own defined type
    const response1: Response = await postData(url, data);
    if (!response1.ok) {
      throw response1.status_text;
    }

    const preSignedURLResponse: UploadPreSignedURLResponse =
      response1.json as UploadPreSignedURLResponse;
    const preSignedUrl = preSignedURLResponse.url;
    const metaDataDict = preSignedURLResponse.meta_data_result;

    // step2: put request to s3
    // the response2 is fetch native response type
    const response2 = await fetch(preSignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "content-type": "",
        ...metaDataDict,
      },
    });

    if (!response2.ok) {
      throw response2.statusText;
    }

    rst.status = constants.LOADING_SUCCESS;
    rst.message = "";
  } catch (error: any) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}

// key
export const FILE_TYPE = {
  RPM_NRMSE: "rpm_nrmse",
  PUT: "put",
  VIENNA: "vienna",
};

export const FILE_NAME_FORMAT = {
  [FILE_TYPE.RPM_NRMSE]: {
    text: "PRM & NRMSE Tests Excel",
    format: "rpm_nrmse_<project><_any_suffix>.xlsx",
    example: "rpm_nrmse_jasmine_anyComment.xlsx",
  },
  [FILE_TYPE.PUT]: {
    text: "PUT Algorithm Tests Excel",
    format: "put_<project><_any_suffix>.xlsx",
    example: "put_brahms_anyComment.xlsx",
  },
};

export const UPLOAD_PAGE_SIZE = 1000;

import emailVerificationType from "../types/email-verification-type";

const initialState = {
  emailVerified: true,
  sendEmailStatus: -2,
};

const emailVerificationReducer = (state = initialState, action) => {
    switch (action.type) {
  
      // email verify
      case emailVerificationType.CHECK_EMAIL_VERIFIED_COMMIT:
        return {
          ...state,
          emailVerified: action.payload.status === "success" ? true : false,
        };
  
      case emailVerificationType.SEND_VERIFICATION_EMAIL:
        return {
          ...state,
          sendEmailStatus: 0,
        };
      case emailVerificationType.SEND_VERIFICATION_EMAIL_COMMIT:
        return {
          ...state,
          sendEmailStatus: 1,
        };
      case emailVerificationType.SEND_VERIFICATION_EMAIL_ROLLBACK:
        return {
          ...state,
          sendEmailStatus: -1,
        };
 
      default:
        return state;

    }
  };

  export default emailVerificationReducer;
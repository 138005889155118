import React, { Component, Dispatch } from "react";
import { Multiselect, Button } from "@amzn/awsui-components-react/polaris";
import { LandingResponse } from "../../../model/http-json";
import constants from "../../../config/constants";
// redux
import {
  getPassPercentageData,
  getSingleCaseData,
} from "../../../redux/actions/dashboard-action";
import { connect } from "react-redux";

interface StateProps {
  dashboardReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  data: LandingResponse;
  loadingStatus: number;
  dashboardCategory: string;
  project: string;
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({ selectOptions: {}, errorMsg: "" });

class DashboardOption extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _buildOptions = (optionArray: Array<string>): any => {
    //dynamically generate options
    let options: Array<{ [key: string]: any }> = [];
    optionArray.forEach((element, index) => {
      const optionDict = { label: element, id: index };
      options.push(optionDict);
    });
    return options;
  };

  _onChange = (key: string, selectedOptions: Array<{}>): any => {
    //update state with selected/unselected options
    let curSelectOptions = { ...this.state.selectOptions };
    curSelectOptions[key] = Array.from(selectedOptions, (item) => item);
    this.setState({ selectOptions: curSelectOptions });
  };

  _getPassPercentage = (): any => {
    //validate combinations count: if it's beyond the 1000, ask user to drop some options
    const selectOptions = { ...this.state.selectOptions };
    let combinationsCount = 1;
    Object.values(selectOptions).forEach((element) => {
      if (Object(element).length > 0) {
        combinationsCount *= Object(element).length;
      }
    });

    if (combinationsCount > constants.COMBINATIONS_LIMIT) {
      this.setState({
        errorMsg: `Combinations total number is ${combinationsCount}: it's beyound limit(${constants.COMBINATIONS_LIMIT})! Please unselect some options to reduce the total number.`,
      });
      return;
    } else {
      this.setState({ errorMsg: "" });
    }

    //generate the request and send to BE
    this.props.dispatch(getPassPercentageData(this._generateRequest(),this.props.dashboardCategory, this.props.project));
  };

  _getSingleCase = (): any => {
    //validate: allow user to select one option only for each Multiselect
    const selectOptions = { ...this.state.selectOptions };
    let isBreakLimit = false;
    Object.values(selectOptions).forEach((element) => {
      if (Object(element).length > 1) {
        isBreakLimit = true;
        return;
      }
    });

    if (isBreakLimit) {
      this.setState({
        errorMsg: `For Single Case: only allow one option for each selection below! Please unselect extra options.`,
      });
      return;
    } else {
      this.setState({ errorMsg: "" });
    }

    //generate the request and send to BE
    this.props.dispatch(
      getSingleCaseData(this._generateRequest(), this.props.dashboardCategory, this.props.project)
    );
  };

  _generateRequest = (): any => {
    const selectOptions = { ...this.state.selectOptions };
    let request = {};
    Object.keys(selectOptions).forEach((key) => {
      if (selectOptions[key].length > 0) {
        request[key] = [];
        selectOptions[key].forEach((element: any) =>
          request[key].push(element.label)
        );
      }
    });
    return request;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.loadingStatus &&
      prevProps.loadingStatus !== this.props.loadingStatus &&
      this.props.loadingStatus === constants.LOADING_LOAD
    ) {
      // clear selections when switch between /rpm-nrmse and /put
      this.setState({ selectOptions: {} });
    }
  }

  render() {
    const { data, loadingStatus, dashboardCategory,project } = this.props;

    return (
      <div className="awsui-util-container">
        {/* header */}
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            {/* title */}
            <div className="awsui-util-action-stripe-title">
              <h2>Select Options: {project} / {dashboardCategory}</h2>
            </div>
            {/* button group */}
            <div className="awsui-util-action-stripe-group">
              {dashboardCategory === constants.RPM_NRMSE_CATEGORY && (
                <Button variant="primary" onClick={this._getPassPercentage}>
                  Pass Percentage
                </Button>
              )}
              <Button variant="primary" onClick={this._getSingleCase}>
                Single Case
              </Button>
            </div>
          </div>
        </div>
        <div>
          {/* error message */}
          {this.state.errorMsg !== "" && (
            <div className="text-danger">{this.state.errorMsg}</div>
          )}
          {/* icon */}
          {loadingStatus === constants.LOADING_LOAD && (
            <div
              className="spinner-border text-secondary"
              style={{ width: "20px", height: "20px" }}
            ></div>
          )}
          {/* dynamic options */}
          <div className="awsui-util-spacing-v-s">
            {loadingStatus === constants.LOADING_SUCCESS &&
              Object.keys(data).map((key, index) => (
                <Multiselect
                  key={index}
                  placeholder={key}
                  options={this._buildOptions(data[key])}
                  selectedOptions={this.state.selectOptions[key]}
                  selectedLabel="Selected"
                  ariaRequired={true}
                  checkboxes={true}
                  onChange={(e) =>
                    this._onChange(key, e.detail.selectedOptions)
                  }
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardReducer: state.dashboardReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(DashboardOption);

import authzType from "../types/authz-type";

const initialState = {
  // authz call
  authz: 0,
};

const authzReducer = (state = initialState, action) => {
  switch (action.type) {
    case authzType.AUTHZ_CALL_COMMIT:
      return {
        ...state,
        authz: 1,
      };
    case authzType.AUTHZ_CALL_ROLLBACK:
      return {
        ...state,
        authz: -1,
      };
    default:
      return state;
  }
};

export default authzReducer;

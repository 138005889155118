import React from "react";
import constants, { STATUS_TEXT } from "../../../config/constants";

// default page size
export const defaultPageSize = 10;

// page size option
export const pageSizeOptions = [
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

// define columns
export const columnDefinitions = [
  {
    id: "file_name",
    header: "File Name",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "file_name";
      const ascending = !sortState.sortingDescending;
      return `File Name, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.file_name,
    minWidth: "100px",
  },
  {
    id: "upload_time",
    header: "Upload Time(local)",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "upload_time";
      const ascending = !sortState.sortingDescending;
      return `Upload Time, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.upload_time,
    minWidth: "100px",
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      let className = "";
      if (item.status === STATUS_TEXT[constants.STATUS_DONE]) {
        className = "text-success";
      }
      // item.status === "error" is temporary to be compatible with current BE
      if (item.status === STATUS_TEXT[constants.STATUS_FAIL]) {
        className = "text-danger";
      }
      return <div className={className}>{item.status}</div>;
    },
    minWidth: "100px",
  },
  {
    id: "message",
    header: "Message",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "message";
      const ascending = !sortState.sortingDescending;
      return `Message, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (item.error_message === null ? <div><a href={item.message}>{item.message}</a></div> : item.error_message),
    minWidth: "100px",
  },
];

// define default sorting column
export const defaultSortingColumn = "upload_time";

// define sortable columns
export const sortableColumns = [
  { id: "user", field: "user" },
  { id: "tool", field: "tool" },
  { id: "file_name", field: "file_name" },
  { id: "upload_time", field: "upload_time" },
  { id: "status", field: "status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "user", label: "User", visible: true, editable: false },
      { id: "tool", label: "Tool", visible: true, editable: false },
      { id: "file_name", label: "File Name", visible: true, editable: false },
      {
        id: "upload_time",
        label: "Upload Time",
        visible: true,
        editable: false,
      },
      { id: "status", label: "Status", visible: true },
      { id: "message", label: "Message", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "User",
    propertyKey: "user",
    propertyLabel: "User",
    values: [],
  },
  {
    groupValuesLabel: "Tool",
    propertyKey: "tool",
    propertyLabel: "Tool",
    values: [],
  },
  {
    groupValuesLabel: "Filename",
    propertyKey: "file_name",
    propertyLabel: "Filename",
    values: [],
  },
  {
    groupValuesLabel: "Upload Time",
    propertyKey: "upload_time",
    propertyLabel: "Upload Time",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: Object.values(STATUS_TEXT),
  },
  {
    groupValuesLabel: "Message",
    propertyKey: "message",
    propertyLabel: "Message",
    values: [],
  },
];

// export const features = ["filtering", "pagination", "selection", "sorting"];

import React, { Component } from "react";
// sub components
import { getIcon } from "./file-list";
import { Button, Modal, Alert } from "@amzn/awsui-components-react/polaris";
// constans
import constants from "../../../config/constants";
import { FileStatus } from "../../../model/upload";

type Props = {
  onDismiss: () => void; // onDismiss event
  onClose: () => void; // onClose event
  fileList: Array<File>;
  fileUploadDict: { [key: string]: FileStatus };
} & typeof defaultProps;

const defaultProps = Object.freeze({
  showModal: false,
});

class UploadingModal extends Component<Props> {
  static readonly defaultProps = defaultProps;

  _checkUploadingStatus = () => {
    const { fileList, fileUploadDict } = this.props;

    let hasError: boolean = false;

    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (fileUploadDict[filename]) {
        if (fileUploadDict[filename].status === constants.LOADING_LOAD) {
          return 0;
        }
        if (fileUploadDict[filename].status === constants.LOADING_FAIL) {
          hasError = true;
        }
      }
    }

    if (hasError) {
      return -1;
    }

    return 1;
  };

  render() {
    const {
      showModal,
      onDismiss,
      onClose,
      fileList,
      fileUploadDict,
    } = this.props;
    const uploadingStatus: number = this._checkUploadingStatus(); // uploading status, status of all uploading files

    return (
      <Modal
        header="Uploading"
        visible={showModal}
        onDismiss={onDismiss}
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={uploadingStatus === constants.LOADING_LOAD}
              onClick={onClose}
            >
              Done
            </Button>
          </span>
        }
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          {/* text */}
          {uploadingStatus === constants.LOADING_LOAD && (
            <div>Uploading... Do Not Close!</div>
          )}
          {uploadingStatus === constants.LOADING_SUCCESS && (
            <Alert type="success" header="Success">
              <div>Files are uploaded to s3 and waiting to be parsed.</div>
              <div>The data will be avaliable in 1~2 minutes.</div>
              <br />
              <div>You can close this window now.</div>
            </Alert>
          )}
          {uploadingStatus === constants.LOADING_FAIL && (
            <Alert header="Issues occurred during uploading" type="error">
              Check below information
              {fileList.map((file: File, index: number) => {
                const filename = file.name;
                if (
                  fileUploadDict[filename] &&
                  fileUploadDict[filename].status === constants.LOADING_FAIL
                ) {
                  return (
                    <div
                      key={index}
                    >{`${filename}: ${fileUploadDict[filename].message}`}</div>
                  );
                } else {
                  return null;
                }
              })}
            </Alert>
          )}
          <br />

          {/* icon */}
          {uploadingStatus === constants.LOADING_LOAD && (
            <React.Fragment>
              <div
                className="spinner-border text-secondary"
                role="status"
                style={{ width: "50px", height: "50px" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
              <br />
            </React.Fragment>
          )}

          {/* file list */}
          <br />
          <div style={{ width: "90%" }}>
            {fileList.map((file: File, index: number) => (
              <li key={index} style={{ fontSize: "14px" }}>
                {file.name}{" "}
                <span style={{ marginLeft: "20px" }}>
                  {getIcon(
                    fileUploadDict[file.name].status,
                    fileUploadDict[file.name].message
                  )}
                </span>
              </li>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default UploadingModal;

import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getUploadStatus } from "../../redux/actions/upload-action"; // redux action
// sub components
import UploadHistoryTable from "./history-table/table";
import ManualUploadPanel from "./manual-upload-module/upload-panel";
// constants
import constants from "../../config/constants";
import { UPLOAD_PAGE_SIZE } from "../../config/upload-config";
import { UploadStatusRequest } from "../../model/http-json";
import { username } from "../../cognito-auth/session";
import EmailVerification from "../email-verification/email-verification"
interface StateProps {
  uploadReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class ManualUpload extends Component<Props> {
  static readonly defaultProps = defaultProps;
  interval: any;

  componentDidMount() {
    this._getUploadStatus();

    //fetch data every 30s
    this.interval = setInterval(() => this._getUploadStatus(true), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // get upload status data
  _getUploadStatus = (syncMode: boolean = false) => {
    const requestJson: UploadStatusRequest = {
      initiative: constants.INITIATIVE,
      user_name: username,
      page_size: UPLOAD_PAGE_SIZE,
    };

    this.props.dispatch(getUploadStatus(requestJson, syncMode));
  };

  render() {
    const {
      manualUploadLoadingStatus,
      manualUploadData,
    } = this.props.uploadReducer;

    return (
      <div>
        <EmailVerification />
        <ManualUploadPanel getUploadStatus={this._getUploadStatus} />
        <br />
        <UploadHistoryTable
          data={manualUploadData}
          getData={this._getUploadStatus}
          loadingStatus={manualUploadLoadingStatus}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadReducer: state.uploadReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(ManualUpload);

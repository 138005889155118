import React from "react";
import Plot from "react-plotly.js";
import { Container } from "@amzn/awsui-components-react-v3";
import constants from "../../../config/constants";

const rpmHistogram = (props) => {
  const limit = constants.RPM_NRMSE_LIMIT[0];
  let pass: Array<any> = [];
  let fail: Array<any> = [];
  props.data.forEach((data) => {
    if (data >= limit) {
      pass.push(data);
    } else {
      fail.push(data);
    }
  });
  return rpmNrmseHistogram(props, pass, fail, limit);
};

const nrmseHistogram = (props) => {
  const limit = constants.RPM_NRMSE_LIMIT[1];
  let pass: Array<any> = [];
  let fail: Array<any> = [];
  props.data.forEach((data) => {
    if (data <= limit) {
      pass.push(data);
    } else {
      fail.push(data);
    }
  });
  return rpmNrmseHistogram(props, pass, fail, limit);
};

const rpmNrmseHistogram = (props, pass, fail, limit) => {
  const passPercentage = ((pass.length / props.data.length) * 100).toFixed(2);
  const plotTitle = `${props.title}: ${passPercentage}% (pass percentage)= ${pass.length} (pass) / ${props.data.length} (total)`;
  const passColor = props.color;
  const limitLine = [
    {
      type: "line",
      x0: limit,
      x1: limit,
      y0: 0,
      line: {
        color: "red",
        width: 3,
      },
    },
  ];

  return (
    <Container>
      <Plot
        data={[
          {
            x: [limit],
            text: [`limit: ${limit}`],
            mode: "text",
            textposition: "bottom center",
            textfont: { size: [16], color: "red" },
            showlegend: false,
          },
          {
            x: pass,
            type: "histogram",
            marker: { color: passColor },
            name: "pass",
          },
          {
            x: fail,
            type: "histogram",
            marker: { color: "red" },
            name: "fail",
          },
        ]}
        layout={{ title: plotTitle, autosize: true, shapes: limitLine }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
      />
    </Container>
  );
};

const defaultHistogram = (props) => {
  return (
    <Container>
      <Plot
        data={[
          {
            x: props.data,
            type: "histogram",
            marker: { color: props.color },
          },
        ]}
        layout={{ title: props.title, autosize: true }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
      />
    </Container>
  );
};

const Histogram = (props) => {
  switch (props.title) {
    case constants.RPM_NRMSE_PARAMETER[0]:
      return props.data && props.data.length > 0
        ? rpmHistogram(props)
        : defaultHistogram(props);
    case constants.RPM_NRMSE_PARAMETER[1]:
      return props.data && props.data.length > 0
        ? nrmseHistogram(props)
        : defaultHistogram(props);
    default:
      return defaultHistogram(props);
  }
};

export default Histogram;

const emailVerificationType = {
    // verify email
  CHECK_EMAIL_VERIFIED: "CHECK_EMAIL_VERIFIED",
  CHECK_EMAIL_VERIFIED_COMMIT: "CHECK_EMAIL_VERIFIED_COMMIT",
  CHECK_EMAIL_VERIFIED_ROLLBACK: "CHECK_EMAIL_VERIFIED_ROLLBACK",

  // send verification email
  SEND_VERIFICATION_EMAIL: "SEND_VERIFICATION_EMAIL",
  SEND_VERIFICATION_EMAIL_COMMIT: "SEND_VERIFICATION_EMAIL_COMMIT",
  SEND_VERIFICATION_EMAIL_ROLLBACK: "SEND_VERIFICATION_EMAIL_ROLLBACK",

}

export default emailVerificationType;
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
// middleware
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
// rootReducer, rootSaga
import rootReducer from "./root-reducer";
import rootSaga from "../sagas/root-saga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

//config logger middleware
const loggerMiddleware = createLogger({ collapsed: true });

//create middleware list
const middleware = [sagaMiddleware, loggerMiddleware];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

// then run the saga
sagaMiddleware.run(rootSaga);

export default store;

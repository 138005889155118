import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// bind redux
import { Provider } from "react-redux";
import store from "./redux/store";
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
// react router dom
import { BrowserRouter } from "react-router-dom";
// fontawesome
import "@fortawesome/fontawesome-free/css/all.css";
// global css
import "./index.css";
// our own library, contains css style and colors
// https://code.amazon.com/packages/LENS-React-UI/blobs/mainline/--/src/index.css
import "@amzn/lens-react-ui/lib/main.min.css";
// config
import { loadAppConfig } from "./config/config";

//TODO
loadAppConfig().then((config) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <div className="awsui">
          <App />
        </div>
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
});

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Sidebar as LensSidebar } from "@amzn/lens-react-ui";
import { sidebarData } from "./sidebar-config";


// declare prop check
type Props = {
  collapse: boolean;
  onClick: () => void;
} & typeof defaultProps &
  RouteComponentProps<any>;

// declare init state & default props
const defaultProps = Object.freeze({});

class Sidebar extends Component<Props> {
  // todo: hard coded side bar list for now

  render() {
    const { collapse, onClick } = this.props;
    return (
      <LensSidebar
        title="Project List"
        loading={false}
        failed={false}
        collapse={collapse}
        data={sidebarData}
        onClickToggle={onClick}
        routerHistory={this.props.history}
      />
    );
  }
}

export default (withRouter(Sidebar));

import landingType from "../types/landing-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";

export const getLandingData = (dashboardCategory, project) => ({
  type: landingType.LANDING_SERVICE_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.landingEndpoint(dashboardCategory,project),
        method: "GET",
      },
      commit: {
        type: landingType.LANDING_SERVICE_CALL_COMMIT,
      },
      rollback: {
        type: landingType.LANDING_SERVICE_CALL_ROLLBACK,
      },
    },
  },
});

import dashboard from "../types/dashboard-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";

export const getPassPercentageData = (request,dashboardCategory,project) => ({
  type: dashboard.PASS_PERCENTAGE_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.passPercentageEndpoint(dashboardCategory, project),
        method: "POST",
        json: request,
      },
      commit: {
        type: dashboard.PASS_PERCENTAGE_CALL_COMMIT,
      },
      rollback: {
        type: dashboard.PASS_PERCENTAGE_CALL_ROLLBACK,
      },
    },
  },
});

export const getSingleCaseData = (request, dashboardCategory,project) => ({
  type: dashboard.SINGLE_CASE_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.singleCaseEndpoint(dashboardCategory,project),
        method: "POST",
        json: request,
      },
      commit: {
        type: dashboard.SINGLE_CASE_CALL_COMMIT,
      },
      rollback: {
        type: dashboard.SINGLE_CASE_CALL_ROLLBACK,
      },
    },
  },
});

export const clearSingleCaseData = () => ({
  type: dashboard.SINGLE_CASE_CLEAR_DATA,
});

import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  verifyEmail,
  sendVerificationEmail,
} from "../../redux/actions/email-verification-action";
// sub component
import { Alert, Spinner } from "@amzn/awsui-components-react";
import { username } from "../../cognito-auth/session";

interface StateProps {
  emailVerificationReducer: any;
}
type Props = { dispatch: Dispatch<any> } & typeof defaultProps & StateProps;
type State = typeof initialState;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  visiable: true,
});

class EmailVerification extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  componentDidMount() {
    this.props.dispatch(verifyEmail());
  }

  _sendVerificationEmail = () => {
    this.props.dispatch(sendVerificationEmail());
  };

  _onDismiss = () => {
    this.setState({ visiable: false });
  };

  render() {
    let { emailVerified, sendEmailStatus } = this.props.emailVerificationReducer;
    console.log(emailVerified)
    return (
      <React.Fragment>
        <Alert
          visible={this.state.visiable && !emailVerified}
          onDismiss={this._onDismiss}
          dismissible
          type="error"
        >
          You're <b>NOT</b> in our verified email list. Please click&nbsp;
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={this._sendVerificationEmail}
          >
            here
          </span>
          &nbsp;to send a verification email.
          {sendEmailStatus === 0 && (
            <div className="text-secondary">
              <Spinner size="normal" />
            </div>
          )}
          {sendEmailStatus === 1 && (
            <p className="text-secondary">
              A verification email will be sent to
              <b>{` ${username}@amazon.com`}</b>. Please click the link in the
              email to verify.
            </p>
          )}
        </Alert>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emailVerificationReducer: state.emailVerificationReducer,
  };
};

export default connect(mapStateToProps)(EmailVerification);

import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getLandingData } from "../../redux/actions/landing-action"; // redux action
import DashboardOption from "./option/option";
import DashboardTable from "./table/table";
import { clearSingleCaseData } from "../../redux/actions/dashboard-action";

interface StateProps {
  landingReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps;

const initialState = Object.freeze({
  dashboardCategory: "",
  project: "",
});

class Dashboard extends Component<Props> {
  readonly state = initialState;

  componentDidMount() {
    const { category, project } = this._getUrlInfo();
    this._getData(category, project);
  }

  componentDidUpdate(prevProps, prevState) {
    const { category, project } = this._getUrlInfo();
    // refresh dashboard if url changed
    if (
      (prevState.dashboardCategory &&
        prevState.dashboardCategory !== category) ||
      (prevState.project && prevState.project !== project)
    ) {
      this._getData(category, project);
    }
  }

  _getUrlInfo = () => {
    let category = "";
    let project = "";
    const path = window.location.pathname.split("/");
    if (path && path.length === 3) {
      category = path[2];
      project = path[1];
    }
    return { category, project };
  };

  _getData = (cate, proj) => {
    const dashboardCategory = cate;
    const project = proj;
    this.setState({ dashboardCategory, project });
    this.props.dispatch(getLandingData(dashboardCategory, project));
    this.props.dispatch(clearSingleCaseData());
  };

  render() {
    const { landingData, loadingStatus } = this.props.landingReducer;

    return (
      <div>
        <DashboardOption
          data={landingData}
          loadingStatus={loadingStatus}
          dashboardCategory={this.state.dashboardCategory}
          project={this.state.project}
        />
        <DashboardTable
          dashboardCategory={this.state.dashboardCategory}
          project={this.state.project}
        />
        {/* 
        <DashboardPlot />: placeholder
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    landingReducer: state.landingReducer,
    dashboardReducer: state.dashboardReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(Dashboard);

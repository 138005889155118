import { combineReducers } from "redux";
import authzReducer from "./reducers/authz-reducer";
import uploadReducer from "./reducers/upload-reducer";
import landingReducer from "./reducers/landing-reducer";
import dashboardReducer from "./reducers/dashboard-reducer";
import emailVerificationReducer from "./reducers/email-verification-reducer";

//reducer list
const reducers = {
  authzReducer,
  uploadReducer,
  landingReducer,
  dashboardReducer,
  emailVerificationReducer
};

// combine all reducers to rootReducer
const rootReducer = combineReducers(reducers);

export default rootReducer;

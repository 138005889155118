class Endpoints {
  /* ***************************************************************** */
  /* Upload related */
  // file name check
  uploadFileNameCheck = () => "/upload/check";

  // generate pre-signed url
  uploadPreSignedURL = () => "/upload";

  // upload status
  uploadStatus = () => "/upload/status";

  /* ***************************************************************** */
  /* Landing related */
  // dashboard options
  landingEndpoint = (category,project) => `/dashboard/categories/${category}/projects/${project}/options`;
  passPercentageEndpoint = (category,project) => `/dashboard/categories/${category}/projects/${project}/pass_percentage`;
  singleCaseEndpoint = (category,project) =>
    `/dashboard/categories/${category}/projects/${project}/single_case`;

  verifyEmailEndpoint = () => `/email/verify`;
  sendverificationEmailEndpoint = () => `/email/send-verification`;
}

const endpoints = new Endpoints();
export default endpoints;

import base_config from "./base_config";

const dev_config = {
  ...base_config,
  STAGE: "dev",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_98VCRNfCo",
    aws_user_pools_web_client_id: "6utmd5dcgqb781q9q4urlkvv8i",
    oauth: {
      domain: "wise-general-cog-dev.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url
  BASE_URL: "https://5b1rz6igte.execute-api.us-west-2.amazonaws.com/dev",
  // Upload service base url
  UPLOAD_BASE_URL: "https://wrcqb3byf5.execute-api.us-west-2.amazonaws.com/dev",
  // Genie url, used for email  verification service, TODO: discuss if to create a common service
  GENIE_URL: "https://y2o9r23uk6.execute-api.us-west-2.amazonaws.com/dev",
};

export default dev_config;

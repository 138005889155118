import React, { Component } from "react";
import { Grid, Container, Header } from "@amzn/awsui-components-react-v3";
import { connect } from "react-redux";

import constants from "../../../config/constants";
import Histogram from "./histogram";

interface StateProps {
  dashboardReducer: any;
}

type Props = {
  dashboardCategory: string;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

class SingleCaseDefaultPlot extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    const {
      tableData,
      tableDataLoadingStatus,
      dashboardType,
    } = this.props.dashboardReducer;
    const { dashboardCategory } = this.props;

    let histogramData = [] as Array<Array<number>>;
    //default case: dashboardCategory === constants.RPM_NRMSE_CATEGORY
    let parameters = constants.RPM_NRMSE_PARAMETER;
    let colors = constants.RPM_NRMSE_COLOR;
    if (dashboardCategory === constants.PUT_CATEGORY) {
      parameters = constants.PUT_PARAMETER;
      colors = constants.PUT_COLOR;
    }
    tableDataLoadingStatus === constants.LOADING_SUCCESS &&
      Object(tableData).length > 0 &&
      parameters.forEach((parameter) => {
        let tmpHistorygramData = [] as Array<number>;
        tableData.forEach((element) => {
          tmpHistorygramData.push(element[parameter]);
        });
        histogramData.push(tmpHistorygramData);
      });
    return (
      <div>
        {dashboardType === constants.SINGLE_CASE &&
          tableDataLoadingStatus === constants.LOADING_SUCCESS && (
            <Container header={<Header variant="h2">Histogram</Header>}>
              <Grid
                gridDefinition={parameters.map(() => ({
                  colspan: { l: 6, m: 6, default: 12 },
                }))}
              >
                {parameters.map((parameter, index) => (
                  <Histogram
                    key={index}
                    title={parameter}
                    data={histogramData[index]}
                    color={colors[index]}
                  />
                ))}
              </Grid>
            </Container>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardReducer: state.dashboardReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(SingleCaseDefaultPlot);

import React, { Component } from "react";
import { Grid, Container, Header } from "@amzn/awsui-components-react-v3";
import { connect } from "react-redux";

import constants from "../../../config/constants";
import Bar from "./bar";

interface StateProps {
  dashboardReducer: any;
}

// declare prop check
type Props = {
  selectedItems: Array<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({});

class SingleCaseClusterPlot extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  render() {
    const {
      tableDataLoadingStatus,
      dashboardType,
    } = this.props.dashboardReducer;
    const { selectedItems } = this.props;

    let histogramData = [] as Array<Array<number>>;
    const parameters = constants.CLUSTER_PARAMETER;
    tableDataLoadingStatus === constants.LOADING_SUCCESS &&
      Object(selectedItems).length > 0 &&
      selectedItems.forEach((item) => {
        let tmpHistorygramData = [] as Array<number>;
        parameters.forEach((parameter) => {
          tmpHistorygramData.push(item[parameter]);
        });
        histogramData.push(tmpHistorygramData);
      });

    return (
      <div>
        {dashboardType === constants.SINGLE_CASE &&
          tableDataLoadingStatus === constants.LOADING_SUCCESS && (
            <Container header={<Header variant="h2">Histogram</Header>}>
              <Grid
                gridDefinition={selectedItems.map(() => ({
                  colspan: { l: 6, m: 6, default: 12 },
                }))}
              >
                {selectedItems.map((item, index) => (
                  <Bar
                    key={index}
                    title={`${item.series}_${item.date}`}
                    x={constants.CLUSTER_PARAMETER}
                    y={histogramData[index]}
                  />
                ))}
              </Grid>
            </Container>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardReducer: state.dashboardReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(SingleCaseClusterPlot);

import React, { Component } from "react";
import {
  Table,
  Button,
  TablePropertyFiltering,
  TablePagination,
  TableSelection,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from "@amzn/awsui-components-react";
import {
  defaultPageSize,
  columnDefinitions,
  defaultSortingColumn,
  sortableColumns,
  visibleColumns,
  filteringOptions,
  features,
} from "./table-config";
import { exportCSVFunc } from "@amzn/lens-react-ui";
import constants from "../../../config/constants";
import SingleCaseDefaultPlot from "../plot/single-case-default";
import SingleCaseClusterPlot from "../plot/single-case-cluster";
// redux
import { connect } from "react-redux";

interface StateProps {
  dashboardReducer: any;
}

type Props = {
  dashboardCategory: string;
  project: string;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
  selectedItems: [] as any[],
});

class DashboardTable extends Component<Props, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  _onSelectionChange = (event) => {
    const { selectedItems } = event.detail;
    this.setState({ selectedItems });
  };

  componentDidUpdate(prevProps, prevState) {
    // clear selected items if switch url
    if (
      (prevProps.dashboardCategory &&
        prevProps.dashboardCategory !== this.props.dashboardCategory) ||
      (prevProps.project && prevProps.project !== this.props.project)
    ) {
      this.setState({ selectedItems: [] });
    }
  }

  render() {
    const { tableData, tableDataLoadingStatus, errorMsg } =
      this.props.dashboardReducer;
    const { dashboardCategory, project } = this.props;

    let tableHeaders = [] as Array<string>;
    tableDataLoadingStatus === constants.LOADING_SUCCESS &&
      Object(tableData).length > 0 &&
      Object.keys(tableData[0]).forEach((key) => {
        tableHeaders.push(key);
      });

    return (
      <div>
        <Table
          loadingText="Loading resources"
          columnDefinitions={columnDefinitions(tableHeaders)}
          loading={tableDataLoadingStatus === constants.LOADING_LOAD}
          items={tableData}
          features={features}
          header={
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>
                  Dashboard: {project} / {dashboardCategory} &nbsp;
                  <span className="awsui-util-header-counter">{`(${tableData.length})`}</span>
                </h2>
              </div>
              <div className="awsui-util-action-stripe-group">
                <Button
                  icon="download"
                  variant="primary"
                  onClick={() => exportCSVFunc(tableData, "dashboard")}
                >
                  Export Table
                </Button>
                <Button disabled variant="primary">
                  Line Chart
                </Button>
                <Button disabled variant="primary">
                  Box Plot
                </Button>
              </div>
            </div>
          }
          empty={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No resources</b>
              </div>
              {tableDataLoadingStatus === constants.LOADING_FAIL ? (
                <p className="text-danger">{errorMsg}</p>
              ) : (
                <p className="awsui-util-mb-s">No resources to display.</p>
              )}
            </div>
          }
          noMatch={
            <div className="awsui-util-t-c">
              <div className="awsui-util-pt-s awsui-util-mb-xs">
                <b>No matches</b>
              </div>
              <p className="awsui-util-mb-s">We can’t find a match.</p>
              <div className="awsui-util-mb-m">
                <Button>Clear filter</Button>
              </div>
            </div>
          }
        >
          <TablePropertyFiltering
            filteringOptions={filteringOptions(tableHeaders)}
            groupValuesText="Values"
            groupPropertiesText="Properties"
            operationAndText="and"
            operationNotAndText="and not"
            operationOrText="or"
            operationNotOrText="or not"
            clearFiltersText="Clear filter"
            placeholder="Filter resources by property or value"
            allowFreeTextFiltering={true}
            filteringCountTextFunction={(count) =>
              `${count} ${count === 1 ? "match" : "matches"}`
            }
          ></TablePropertyFiltering>
          <TableSelection
            selectedItems={this.state.selectedItems}
            onSelectionChange={this._onSelectionChange}
            keepSelection={true}
          ></TableSelection>
          <TablePagination
            pageSize={defaultPageSize}
            onPaginationChange={this._onPaginationChange}
          ></TablePagination>
          <TableSorting
            sortingColumn={defaultSortingColumn}
            sortableColumns={sortableColumns(tableHeaders)}
            sortingDescending={true}
          ></TableSorting>
          <TablePreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
          >
            <TablePageSizeSelector
              title="Page size"
              options={[
                { value: 3, label: "3 items" },
                { value: 5, label: "5 items" },
                { value: 10, label: "10 items" },
              ]}
            />
            <TableWrapLines
              label="Wrap lines"
              description="Enable to wrap table cell content, disable to truncate text."
            />
            <TableContentSelector
              title="Select visible columns"
              options={visibleColumns(tableHeaders)}
            />
          </TablePreferences>
        </Table>
        {dashboardCategory !== constants.CLUSTER_CATEGORY && (
          <SingleCaseDefaultPlot dashboardCategory={dashboardCategory} />
        )}
        {dashboardCategory === constants.CLUSTER_CATEGORY && (
          <SingleCaseClusterPlot selectedItems={this.state.selectedItems} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardReducer: state.dashboardReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(DashboardTable);

import dev_config from "./stage-config/dev_config";
import beta_config from "./stage-config/beta_config";
import prod_config from "./stage-config/prod_config";
export let config = dev_config;

const timeout = (ms, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error("timeout"));
    }, ms);
    promise.then(resolve, reject);
  });
};

export async function loadAppConfig() {
  console.log("[Fetch Config]start async call");
  let stage: any = "dev";
  try {
    stage = await timeout(
      1000,
      fetch(`/config/config.json`).then((response) => response.json())
    );
  } catch (e) {
    stage = { stage: "dev" };
  }
  console.log(`Fetch Config loaded config=${JSON.stringify(stage)}`);
  switch (stage.stage) {
    case "beta":
      config = beta_config;
      break;
    case "prod":
      config = prod_config;
      break;
    default:
      config = dev_config;
      break;
  }
  return config;
}
